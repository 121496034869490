import {Component, Input} from '@angular/core';
import {FilesHandler} from 'portal/chunks/files/files-handler/files-handler.service';
import {fileIdToDownloadLink} from 'portal/chunks/files/files.functions';
import {FileWrapper} from 'portal/chunks/files/files.interfaces';
import {saveAs} from 'shared/utils/utils';

@Component({
    selector: 'app-files-list',
    template: `
        <div *ngFor="let file of filesHandler.filesArray$ | async"
              (click)="download(file)"
              [class.cursor-pointer]="!forEdit"
              class="rounded bg-secondary bg-opacity-10 p-3 position-relative"
        >
            <ng-container *ngIf="!!file.url else imgTpl64">
                 <span *ngIf="!file.isImage else imgTpl" class="me-1" style="white-space: normal">{{ file.name }}</span>
                <ng-template #imgTpl>
                    <img [src]="file.url" class="img-fluid" alt="" />
                </ng-template>
            </ng-container>

            <ng-template #imgTpl64>
              <img src="{{ file | base64FromBlob | async }}" class="img-fluid" alt=""/>
            </ng-template>


            <i *ngIf="forEdit" class="fa fa-times-circle icon-button position-absolute top-0 end-0" (click)="filesHandler.removeFile(file)"></i>
      </div>
    `,
    styles: [`
        :host {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }
    `]
})

export class FilesListComponent {
    @Input() filesHandler: FilesHandler;
    @Input() forEdit: boolean = false;

    download(file: FileWrapper) {
        if (this.forEdit) {
            return;
        }
        const url = fileIdToDownloadLink(file.guid);
        saveAs(url);
    }
}
