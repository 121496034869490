import {Pipe, PipeTransform} from '@angular/core';
import {fromPromise} from "rxjs/internal-compatibility";
import {Observable} from "rxjs";

@Pipe({
    standalone: true,
    name: 'base64FromBlob'
})
export class Base64FromBlobPipe implements PipeTransform {
    transform(blob: File): Observable<string> {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return fromPromise(new Promise((resolve, _) => {
            const reader = new FileReader();
            // @ts-ignore
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        }));
    }
}
