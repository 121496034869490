import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BindObservable } from 'bind-observable';
import { isObject, isString } from 'lodash-es';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { noPhotoSrc } from 'portal/chunks/files/files.functions';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

class CropperImageHandler {
    @BindObservable()
    cropperImage: string | File | Blob | undefined = undefined;
    cropperImage$!: Observable<string | File | Blob  | false>;
    cropperImageType$ = this.cropperImage$.pipe(
        map(cropperImage => isObject(cropperImage) ? 'file'
            : isString(cropperImage) ? 'base64' : 'none'),
    );
    state: ImageCroppedEvent;

    handleCrop(ev: ImageCroppedEvent) {
        this.state = ev;
    }

    onFail() {
        const cropperImage = this.cropperImage;
        this.cropperImage = undefined;
        setTimeout(() => this.cropperImage = cropperImage);
    }
}

@Component({
    selector: 'app-image-cropper',
    template: `
        <div [ngSwitch]="handler.cropperImageType$ | async" class="employee-modal-image-container"
             (drop)="drop($event)" (dragover)="$event.preventDefault()">
            <image-cropper *ngSwitchCase="'base64'"
                           [imageBase64]="$any(handler.cropperImage)"
                           (loadImageFailed)="handler.onFail()"
                           [roundCropper]="true"
                           [maintainAspectRatio]="true"
                           [outputType]="'file'"
                           [onlyScaleDown]="true"
                           [resizeToWidth]="500"
                           alignImage="center"
                           (imageCropped)="handler.handleCrop($event)"
            ></image-cropper>
            <image-cropper *ngSwitchCase="'file'"
                           [imageFile]="$any(handler.cropperImage)"
                           (loadImageFailed)="handler.onFail()"
                           [roundCropper]="true"
                           [maintainAspectRatio]="true"
                           [outputType]="'file'"
                           [onlyScaleDown]="true"
                           [resizeToWidth]="500"
                           alignImage="center"
                           (imageCropped)="handler.handleCrop($event)"
            ></image-cropper>
            <img *ngSwitchDefault [src]="noPhotoSrc"/>
        </div>
        <div class="d-flex justify-content-between mt-3">
            <button type="button" class="btn btn-primary"
                    (click)="fileInput.click()">
                <i class="fa fa-upload"></i>
                Загрузить
            </button>
            <button type="button" class="btn btn-primary"
                    (click)="save.emit({file: handler.state.file, fileName: fileName})">
                <i class="fa fa-save"></i>
                Сохранить
            </button>
            <input #fileInput type="file" id="upload" class="d-none" accept="image/*" (change)="addPhoto($event.target['files'])"/>
        </div>
    `,
})

export class ImageCropperComponent {
    noPhotoSrc = noPhotoSrc;
    handler = new CropperImageHandler();
    fileName: string = null;
    @Input()
    set image(image: string | File | Blob | undefined) {
        this.handler.cropperImage = image;
    }

    @Output() save = new EventEmitter<{file, fileName}>();

    constructor() {
        if (!HTMLCanvasElement.prototype.toBlob) {
            Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
                value: function(callback, type, quality) {
                    const canvas = this;
                    setTimeout(function() {
                        const binStr = atob(canvas.toDataURL(type, quality).split(',')[1]),
                            len = binStr.length,
                            arr = new Uint8Array(len);

                        for (let i = 0; i < len; i++) {
                            arr[i] = binStr.charCodeAt(i);
                        }
                        callback(new Blob([arr], { type: type || 'image/png' }));
                    });
                },
            });
        }
    }

    addPhoto(files: FileList) {
        this.handler.cropperImage = files.item(0);
        this.fileName = files.item(0).name;
    }

    drop(ev: DragEvent) {
        ev.preventDefault();
        if (ev.dataTransfer.files) {
            this.addPhoto(ev.dataTransfer.files);
        }
    }
}
