import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { SingleFile } from 'portal/chunks/files/files.interfaces';
import { catchError, map } from 'rxjs/operators';
import { ApiResponse } from 'shared/interfaces/response';
import {environment} from "../../../../environments/environment";

export interface SingleFileV3 extends SingleFile {
  guid: string;
  basename: string;
  extension: string;
  size: number;
  type: string;
  user_id: number;
  filename: string;
  mime_type: string;
  url: string;
  created_at: Date;
  updated_at: Date;
}

@Injectable({ providedIn: 'root' })
export class FilesV3Service {
  constructor(private http: HttpClient, private toastr: ToastrService) { }

  upload(f: File) {
    const formData = new FormData();
    formData.append('file', f);
    return this.http.post<ApiResponse<SingleFileV3>>(`/api/v2/filestorage/upload`, formData)
      .pipe(
        catchError<any, any>((err: HttpErrorResponse) => {
          if (err.status === 413) {
            this.toastr.error(`Файл слишком большой. Максимальный размер ${environment.maxFileUploadSize}`);
          }
          throw err;
        }),
        map(it => it.data),
      );
  }

  info(guid: string) {
    return this.http.get<ApiResponse<SingleFileV3>>(`/api/v2/filestorage/info/${guid}`)
      .pipe(map(it => it.data));
  }

  content(guid: string) {
    return this.http.get<any>(`/api/v2/filestorage/content/${guid}`)
      .pipe(map(it => it.data));
  }

  download(guid: string) {
    return this.http.get<any>(`/api/v2/filestorage/download/${guid}`)
      .pipe(map(it => it.data));
  }
}
