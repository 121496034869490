import {CommonModule, NgOptimizedImage} from '@angular/common';
import { NgModule } from '@angular/core';

import { FilesListComponent } from './files-list.component';
import {ObjectUrlPipe} from "portal/chunks/files/object-url-pipe/object-url-pipe.pipe";
import {Base64FromBlobPipe} from "portal/chunks/files/base64-pipe/base64FromBlob.pipe";

@NgModule({
    imports: [CommonModule, NgOptimizedImage, ObjectUrlPipe, Base64FromBlobPipe],
  exports: [FilesListComponent],
  declarations: [FilesListComponent],
})
export class FilesListModule {}
