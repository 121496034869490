export const environment = {
    title: 'MI Portal',
    production: true,
    sentrySettings: {
        dsn: 'https://2e63c16fec1940c5a95014bfa16b528f@sentry.mediainstinctgroup.ru/9',
        environment: 'production',
        ignoreErrors: [
            "Cannot read properties of undefined (reading 'calculateDimension')",
            "Maximum call stack size exceeded.",
            "undefined is not an object (evaluating 'this.minHandleElement.calculateDimension')",
            "null is not an object (evaluating 'xe.unsubscribe')",
            "Cannot read properties of null (reading 'unsubscribe')"
        ]
    },
    maxFileUploadSize: '500 Мб',
    appVersion: require('../../../../package.json').version,
};
