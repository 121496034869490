import {PropStub} from 'shared/modules/table/table-config.model';

export interface SetOfFiles {
    id: number;
    category_id: number;
    name: string;
    description: string;
    tag_list?: string;
    created_by_user_id: number;
    created_at: string;
    updated_at: string;
    published_at: string;
    deleted_at?: any;
    created_by_user_name: string;
    files: SingleFile[];
    file_guids?: string[];
}

export interface SingleFile {
    guid: string;
    basename: string;
    extension: string;
    size: number;
    type: string;
    url: string;
}

export class FileWrapper implements Partial<File> {
    get guid() {
        return this.payload.guid;
    }

    get basename() {
        return this.payload.basename;
    }

    get extension() {
        return this.payload.extension;
    }

    get size() {
        return this.payload.size;
    }

    get type() {
        return this.payload.type;
    }

    get lastModified() {
        return this.payload.guid as any;
    }

    get name() {
        return this.payload.extension
            ? `${this.payload.basename}.${this.payload.extension}`
            : this.payload.basename;
    }

    get isImage(): boolean {
        return this.payload.type.includes('image');
    }

    get url() {
        return this.payload.url;
    }

    constructor(public payload: SingleFile) {
    }
}

export const SetOfFilesPropStub = PropStub<SetOfFiles>();

