import { Inject, Injectable, InjectionToken, OnDestroy } from '@angular/core';
import { isBoolean } from 'lodash-es';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { MenuItem } from 'shared/modules/navigation/sidebar-menu-item/menu-item.model';
import { Utils } from 'shared/utils/utils';
import { isNullOrUndefined } from 'shared/utils/utils';
import { PermissionsService, permStub } from '../../services/permissions.service';
import packageJson from '../../../../../../package.json';

export const menuItemsFactory = (permissionsService: PermissionsService): Observable<MenuItem[]> =>
    combineLatest([
        permissionsService.sections$,
    ]).pipe(
        map(([sections$]) =>
            [
                {
                    label: 'Основное',
                    isOpened: true,
                    children: [
                        {
                            icon: 'fa fa-fw fa-users',
                            label: 'Сотрудники',
                            route: '/employees',
                            permission: permStub.v2.Employee.view,
                        },
                        {
                            icon: 'fa fa-fw fa-map',
                            label: 'Карта офиса',
                            route: '/map',
                            permissionCallback(service: PermissionsService) {
                                return service.getPermissionValue(permStub.v2.OfficeMap.has_access) && !service.getPermissionValue(permStub.v2.Booking.has_access);
                            },
                        },
                        {
                            icon: 'fa fa-fw fa-calendar',
                            label: 'Бронирование',
                            route: '/place-booking',
                            permission: permStub.v2.Booking.has_access,
                        },
                        {
                            icon: 'fa fa-fw fa-percentage',
                            label: 'Занятость',
                            route: '/percent-tracking',
                            permission: permStub.v2.TimeTracking.has_access,
                        },
                        {
                            icon: 'fa fa-fw fa-lock',
                            label: 'Генератор паролей',
                            route: '/password-gen',
                            permission: permStub.v2.Generator.has_access,
                        },
                        {
                            icon: 'fas fa-book-reader',
                            label: 'AMIGO',
                            route: 'https://myamigo.ru/',
                            external: true,
                        },
                        {
                            icon: 'fas fa-signature',
                            label: 'КЭДО',
                            route: 'https://online.saby.ru/',
                            external: true,
                        },
                        {
                            icon: 'fas fa-star',
                            label: 'MI Stars',
                            route: 'https://mistars.ru/',
                            external: true,
                        },
                        {
                            icon: 'fa fa-fw fa-building',
                            label: 'Структура компании',
                            route: '/company-structure',
                        },
                    ],
                },
                {
                    label: 'Заявки и обращения',
                    isOpened: true,
                    children: [
                        {
                            icon: 'fa fa-fw fa-server',
                            label: 'Задачи для IT отдела',
                            route: '/it-tasks',
                            permission: permStub.v2.ItTask.has_access,
                        },
                        {
                            icon: 'fa fa-fw fa-cogs',
                            label: 'Заявки в АХО',
                            children: [
                                {
                                    icon: 'fa fa-fw fa-wrench',
                                    label: 'Задачи',
                                    route: '/service-desk/administrative',
                                    permission: permStub.v2.ServiceDesk.administrative.has_access,
                                },
                                {
                                    icon: 'fa fa-fw fas fa-gifts',
                                    label: 'Подарки',
                                    route: '/service-desk/gifts',
                                    permission: permStub.v2.Gift.has_access,
                                },
                            ]
                        },
                        {
                            icon: 'fa fa-fw fa-file-contract',
                            label: 'Запрос документов',
                            route: '/service-desk/docs',
                            permission: permStub.v2.ServiceDesk.docs.has_access,
                        },
                        {
                            icon: 'fa fa-fw fa-user-plus',
                            label: 'Заявки на подбор персонала',
                            route: '/recruitment',
                            permission: permStub.v2.Recruitment.has_access,
                        },
                        // {
                        //     icon: 'fa fa-fw fa-universal-access',
                        //     label: 'Заявки в HR',
                        //     children: [
                        //
                        //         {
                        //             icon: 'far fa-fw fa-sun',
                        //             label: 'Заявки на отпуск',
                        //             route: '/vacations',
                        //             permission: permStub.v2.Vacation.view_applications,
                        //         },
                        //         {
                        //             icon: 'fas fa-fw fa-sun',
                        //             label: 'Отправить заявку на отпуск',
                        //             route: '/vacations/send',
                        //             permission: permStub.v2.Vacation.send_applications,
                        //         },
                        //         {
                        //             icon: 'fas fa-fw fa-sun',
                        //             label: 'Планирование отпуска',
                        //             route: '/vacation-scheduler/calendar',
                        //             permissionCallback(service: PermissionsService) {
                        //                 return service.getPermissionValue(permStub.v2.VacationScheduler.has_access);
                        //             },
                        //         },
                        //         {
                        //             icon: 'fa-fw fas fa-stream',
                        //             label: 'Управление отпусками',
                        //             route: '/vacation-scheduler/gantt',
                        //             permissionCallback(service: PermissionsService) {
                        //                 return service.getPermissionValue(permStub.v2.VacationScheduler.control)
                        //                     || service.getPermissionValue(permStub.v2.VacationScheduler.view_subordinated)
                        //                     || service.getPermissionValue(permStub.v2.VacationScheduler.gantt_view);
                        //             },
                        //         },
                        //     ],
                        // },
                    ],
                },
                {
                    label: 'Продукты',
                    isOpened: false,
                    children: [
                        {
                            icon: 'far fa-fw fa-pusher',
                            label: 'ERP',
                            route: '/sections/products/erp',
                        },
                        {
                            icon: 'far fa-fw fa-pusher',
                            label: 'MI Partner Center OOH',
                            route: '/sections/products/mi-partner-center-ooh',
                        },
                        {
                            icon: 'far fa-fw fa-pusher',
                            label: 'MI Portal',
                            route: '/sections/products/mi-portal',
                        },
                        {
                            icon: 'far fa-fw fa-pusher',
                            label: 'ТВ Стрим',
                            route: '/sections/products/tv-strim',
                            children: [
                                {
                                    icon: 'far fa-fw fa-pusher',
                                    label: 'Сетки',
                                    route: '/sections/products/broadcast-grids',
                                },
                                {
                                    icon: 'far fa-fw fa-pusher',
                                    label: 'Бот 2.0',
                                    route: '/sections/products/tvbot-v2',
                                },
                                {
                                    icon: 'far fa-fw fa-pusher',
                                    label: 'TV Dashboard',
                                    route: '/sections/products/tv-dashboard',
                                },
                            ],
                        },
                        {
                            icon: 'far fa-fw fa-pusher',
                            label: 'Analytics space (vpn)',
                            external: true,
                            route: 'https://tools.mediainstinctgroup.ru/',
                        },
                        // {
                        //     icon: 'far fa-fw fa-pusher',
                        //     label: 'Videotron 1.5',
                        //     route: '/sections/products/videotron',
                        // },
                        // {
                        //     icon: 'far fa-fw fa-pusher',
                        //     label: 'Flight Media Mix Calculator',
                        //     route: '/sections/products/FlightMediaMixCalc',
                        // },
                        // {
                        //     icon: 'far fa-fw fa-pusher',
                        //     label: 'Частотный планировщик',
                        //     route: '/sections/products/FreqPlanner',
                        // },
                    ],
                },
                {
                    label: 'Документы',
                    isOpened: false,
                    children: sections$.filter(section => !section.hidden).map(section => ({
                        icon: section.icon ? `fa fa-fw ${section.icon}` : 'fa fa-fw fa-circle',
                        label: section.name,
                        route: `/sections/${section.code}`,
                        permission: section.has_access,
                    })),
                },
                {
                    label: 'Сервисы',
                    children: [
                        {
                            icon: 'fa fa-fw fa-university',
                            label: 'Медиа заказы',
                            route: 'http://erp.mediainstinctgroup.ru/',
                            external: true,
                        },
                    ],
                },
                {
                    label: 'Datafeed',
                    permission: permStub.v2.Datafeed.has_access,
                    children: [
                        {
                            icon: 'fas fa-fw fa-file-import',
                            label: 'Источники',
                            route: `/datafeed/sources`,
                        },
                        {
                            icon: 'fas fa-fw fa-file-code',
                            label: 'Целевые',
                            route: `/datafeed/targets`,
                        },

                    ],
                },
                {
                    label: 'HR',
                    isOpened: false,
                    children: [
                        {
                            icon: 'fa fa-fw fa-user',
                            label: 'Стафф-формы',
                            route: '/staff-forms',
                            permission: permStub.v2.StaffForm.view,
                        },
                        {
                            icon: 'fas fa-fw fa-envelope-open',
                            label: 'Приветственное письмо',
                            route: '/welcome-letter',
                            permission: permStub.v2.WelcomeLetter.has_access,
                        },
                        {
                            icon: 'fa-fw fas fa-graduation-cap',
                            label: 'Обучение',
                            route: '/training',
                            permission: permStub.v2.Training.has_access,
                        },
                        {
                            icon: 'fa-fw fas fa-image',
                            label: 'Баннеры',
                            route: '/banners-settings',
                            permission: permStub.v2.Banner.has_access,
                        },
                    ],
                },
                {
                    label: 'АХО',
                    isOpened: false,
                    children: [
                        {
                            icon: 'fa fa-fw fas fa-gifts',
                            label: 'Подарки',
                            permission: permStub.v2.Gift.control,
                            children: [
                                {

                                    icon: 'fa fa-fw fa-circle',
                                    label: 'Категории',
                                    route: '/gifts/gift-category'
                                },
                                {

                                    icon: 'fa fa-fw fa-circle',
                                    label: 'Типы',
                                    route: '/gifts/gift-type'
                                },
                                {
                                    icon: 'fa fa-fw fa-circle',
                                    label: 'Праздники',
                                    route: '/gifts/occasions'
                                },
                                {
                                    icon: 'fa fa-fw fa-circle',
                                    label: 'Каталог',
                                    route: '/gifts/catalogue',
                                },
                                {
                                    icon: 'fa fa-fw fa-circle',
                                    label: 'Получатели',
                                    route: '/gifts/recipients',
                                },
                            ]
                        },
                    ]
                },
                {
                    label: 'Справочники',
                    children: [
                        {
                            icon: 'fa fa-fw fa-book',
                            label: 'Агентства',
                            route: '/agencies',
                            permission: permStub.v2.Company.view,
                        },
                        {
                            icon: 'fa fa-fw fa-user-tie',
                            label: 'Клиенты',
                            route: '/clients',
                            permission: permStub.v2.Client.view,
                        },
                        {
                            icon: 'fa fa-fw fa-book',
                            label: 'Юр. лица',
                            route: '/companies',
                            permission: permStub.v2.Company.view,
                        },
                        {
                            icon: 'fa fa-fw fa-book',
                            label: 'Департаменты (Портал)',
                            route: '/departments-portal',
                            permission: permStub.v2.Company.view,
                        },
                        {
                            icon: 'fa fa-fw fa-book',
                            label: 'Подразделения (Портал)',
                            route: '/divisions-portal',
                            permission: permStub.v2.Company.view,
                        },
                        {
                            icon: 'fa fa-fw fa-book',
                            label: 'Департаменты (фин. отчетность)',
                            route: '/departments-for-percent-tracking',
                            permission: permStub.v2.Company.control,
                        },
                        {
                            icon: 'fa fa-fw fa-book',
                            label: 'Должности (Портал)',
                            route: '/positions-portal',
                            permission: permStub.v2.Company.view,
                        },
                        {
                            icon: 'fa fa-fw fa-book',
                            label: 'Департаменты (1С)',
                            route: '/departments-official',
                            permission: permStub.v2.Company.view,
                        },
                        {
                            icon: 'fa fa-fw fa-book',
                            label: 'Должности (1C)',
                            route: '/positions-portal-official',
                            permission: permStub.v2.Company.view,
                        },
                        // {
                        //     icon: 'fa fa-fw fa-book',
                        //     label: 'Типы отпуска',
                        //     route: '/vacation-types',
                        //     permission: permStub.applications.vacationTypesRef.view,
                        // },
                        // {
                        //     icon: 'fa fa-fw fa-book',
                        //     label: 'Оборудование',
                        //     route: '/hardware',
                        //     permission: permStub.administration.hardware.view,
                        // },
                    ],
                },
                {
                    label: 'Администрирование',
                    children: [
                        {
                            icon: 'fa fa-fw fa-unlock-alt',
                            label: 'Роли',
                            route: '/roles',
                            permission: permStub.v2.Role.has_access,
                        },
                        {
                            icon: 'fa fa-fw fa-user',
                            label: 'Пользователи',
                            route: '/users',
                            permission: permStub.v2.User.view,
                        },
                        // {
                        //     icon: 'fa fa-fw fa-laptop',
                        //     label: 'Регистрация компьютеров',
                        //     route: '/computers',
                        //     permission: permStub.administration.computers.view,
                        // },
                        // {
                        //     icon: 'fa fa-fw fa-network-wired',
                        //     label: 'Сети',
                        //     route: '/networks',
                        //     permission: permStub.administration.networks.view,
                        // },
                        // {
                        //     icon: 'fa fa-fw fa-info',
                        //     label: 'Info',
                        //     route: '/info',
                        //     permission: permStub.documents.itInfo.list,
                        // },
                        // {
                        //     icon: 'fa fa-fw fa-print',
                        //     label: 'Выдача оборудования',
                        //     route: 'http://itsm/it/assets/register',
                        //     permission: permStub.administration.hardwareIssues.view,
                        //     external: true,
                        // },
                        {
                            icon: 'fa fa-fw fa-clock',
                            label: 'Контроль доступа',
                            route: '/time-tracking',
                            permission: permStub.administration.timeTracking.view,
                        },
                        // {
                        //     icon: 'fa fa-fw fa-angle-double-up',
                        //     label: 'Места для бронирования',
                        //     route: '/booking-map-places',
                        //     permission: permStub.booking.places.view,
                        // },
                        // {
                        //     icon: 'fa fa-fw fa-file-alt',
                        //     label: 'Логгирование',
                        //     route: '/activity-logs',
                        //     permission: permStub.system.activityLogs.view,
                        // },
                        {
                            icon: 'fa fa-puzzle-piece',
                            label: 'Разделы',
                            route: '/sections-settings',
                            permission: permStub.system.config.edit,
                        },
                        // {
                        //     icon: 'fa fa-fw fa-wrench',
                        //     label: 'Настройки системы',
                        //     route: '/system-settings',
                        //     permission: permStub.system.config.edit,
                        // },
                    ],
                },
                {
                    label: `Билд #${packageJson.version.split('.')[2]}`,
                    isOpened: false,
                    route: '',
                },
            ],
        ),
    );

export const MENU_ITEMS = new InjectionToken('MENU_ITEMS');

@Injectable()
export class NavigationService implements OnDestroy {
    private data: MenuItem[] = [];
    private allData: MenuItem[];

    private sub: Subscription;

    constructor(private permissionsService: PermissionsService, @Inject(MENU_ITEMS) allData$: Observable<MenuItem[]>) {
        this.sub = allData$.subscribe((allData) => this.allData = allData);
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    getPermittedMenuItems() {
        this.data = Utils.deepCopy(this.allData);
        this.removeNotPermiitedMenuItems();
        this.removeEmptyMenuItems();
        return this.data;
    }

    private removeNotPermiitedMenuItems() {
        this.nestedForEach((item, index, list) => {
            let isPermitted = true;

            if (!isNullOrUndefined(item.permission)) {

                isPermitted = isBoolean(item.permission)
                    ? item.permission
                    : this.permissionsService.getPermissionValue(item.permission);
            }

            if (!isNullOrUndefined(item.permissionCallback)) {
                isPermitted = item.permissionCallback(this.permissionsService);

            }

            if (!isPermitted) {
                list.splice(index, 1);
            }
        });
    }

    private removeEmptyMenuItems() {
        this.nestedForEach((item, index, list) => {
            if (item.children && item.children.length === 0) {
                list.splice(index, 1);
            }
        });
    }

    private nestedForEach(callback: (item: MenuItem, index?: number, list?: MenuItem[]) => void, submenu: MenuItem[] = this.data) {
        for (let i = submenu.length - 1; i >= 0; i--) {
            const menuItem = submenu[i];
            callback(menuItem, i, submenu);
            if (menuItem.children && menuItem.children.length) {
                this.nestedForEach(callback, menuItem.children);
            }
        }
    }
}
