<div class="d-flex flex-column justify-content-between">
    <div class="d-flex flex-column">
        <h5 class="d-flex align-items-center justify-content-center">
            <span *ngIf="employee.employment_status_name === 'В декрете'"
                  class="badge bg-secondary me-1">
                В декрете
            </span>
            {{ employee.display_name }}
        </h5>
        <p *ngIf="employee.portal_position_name"
           class="text-muted mb-0 position text-center"
        >
            {{ employee.portal_position_name }}
        </p>
        <blockquote *ngIf="employee.about" class="blockquote">
            <p class="mb-0 text-center text-muted small"><em>{{ employee.about }}</em></p>
        </blockquote>
    </div>
    <div class="d-flex flex-column justify-content-between avatar-block mb-2 position-relative">
        <div class="background"></div>
        <ng-container
            *ngIf="(employee.birth_day == dateDay) && (employee.birth_month == dateMonth)">
            <div class="position-relative w-100">
                <div class="position-absolute w-100 lead display-4 text-center"
                     style="z-index: 1000;height: 200px;margin-top: -236px;">👑
                </div>
            </div>
        </ng-container>
        <app-user-photo [photoUrl]="employee.photo_url" [style.width]="'200px'"
                        [style.height]="'200px'"
                        [ngClass]="{birthday: (employee.birth_day == dateDay) && (employee.birth_month == dateMonth),
                                                    avatar: true}"
                        (click)="openPhotoModal(employee.photo_file_guid, [changeAvatarModal,showAvatarModal])"
                        style="margin-left: auto;margin-right: auto;">
        </app-user-photo>
        <p *ngIf="employee.is_another_company"
           class="employee-from-another-company "
        >
            Сотрудник партнерской компании {{ employee.company_name }}
        </p>
    </div>
    <div class="user-data">
        <dl *ngIf="employee.mobile_phone">
            <dt class="text-muted">Телефон:</dt>
            <dd>
                <i class="fa fa-fw fa-mobile"></i>
                <a [href]="'tel:' + employee.mobile_phone"><span>{{ employee.mobile_phone | phone }}</span></a>
            </dd>
        </dl>
        <dl *ngIf="employee.internal_phone">
            <dt class="text-muted">Внутренний телефон:</dt>
            <dd>
                <i class="fa fa-fw fa-phone"></i>
                <span>{{ employee.cross_agency_phone }}</span>
            </dd>
        </dl>
        <dl *ngIf="employee.email">
            <dt class="text-muted">Эл. почта:</dt>
            <dd>
                <i class="fa fa-fw fa-envelope"></i>
                <a href="mailto:{{ employee.email  }}">{{ employee.email }}</a>
            </dd>
        </dl>
        <dl>
            <dt></dt>
            <dd>
                <div class="d-flex flex-row">
                    <a *ngIf="employee.ad_account_name"
                       title="Начать чат в Squadus"
                       href="https://im.mo.unseco.ru/direct/{{employee.ad_account_name}}"
                       target="_blank"
                       class="btn btn-sm bg-secondary d-inline-block teams text-white text-no-capitalize">
                        <img class="me-1" src="/assets/images/squadus.svg" width="16" alt=""> Squadus
                    </a>
                    <button type="button"
                            class="btn btn-sm bg-secondary d-inline-block d-md-none download"
                            (click)="saveVcardHandler()">
                        <i class="fas fa-download text-white m-0">
                        </i>
                    </button>
                </div>
            </dd>
        </dl>

        <dl *ngIf="!employee.is_another_company && employee.agency_name">
            <dt class="text-muted">Агентство:</dt>
            <dd>
                <a [routerLink]="['/employees']"
                   [queryParams]="{search_string: employee.agency_name}">
                    {{ employee.agency_name }}
                </a>
            </dd>
        </dl>
        <dl *ngIf="employee.portal_department_name">
            <dt class="text-muted">
                <span>{{ !employee.is_another_company ? 'Департамент' : 'Команда' }}:</span></dt>
            <dd>
                <a [routerLink]="['/employees']"
                   [queryParams]="{portal_department_id: employee.portal_department_id}">
                    {{ employee.portal_department_name }}
                </a>
            </dd>
        </dl>
        <ng-container *ngIf="workplaceInfoForEmployee$ | async as workplace">
            <dl>
                <dt class="text-muted">Этаж:</dt>
                <dd>
                    {{ workplace.floor | uppercase }}
                    <a [routerLink]="['/map', workplace.floor]"
                       [queryParams]="{ search: employee.display_name }"
                    >(показать на карте)</a>
                </dd>
            </dl>
        </ng-container>

        <dl *ngIf="employee.hide_head === false && employee.head_employee_display_name && employee.head_employee_display_name !== employee.display_name">
            <dt class="text-muted">{{ employee.is_employee_and_head_from_same_company ? 'Руководитель' : 'Куратор' }}:
            </dt>
            <dd>
                <button class="btn btn-link text-no-capitalize"
                        (click)="openHead($event)">
                    {{ employee.head_employee_display_name }}
                </button>
            </dd>
        </dl>

        <dl *ngIf="employee.dont_notify_about_birthday === false">
            <dt class="text-muted">День Рождения:</dt>
            <dd>
                {{ birthDate | date : 'dd MMMM' }}
            </dd>
        </dl>
    </div>
</div>

<ng-template #changeAvatarModal>
    <app-modal [title]="'Загрузка фото'" [modalRef]="modalRef">
        <div class="p-3">
            <app-image-cropper
                [image]="photo"
                (save)="savePhoto($event)"
            ></app-image-cropper>
        </div>
    </app-modal>
</ng-template>

<ng-template #showAvatarModal>
    <app-modal [title]="employee.display_name" [modalRef]="modalRef">
        <div class="p-3">
            <img class="w-100" [src]='employee.photo_url' alt="{{employee.display_name}}">
        </div>
    </app-modal>
</ng-template>
