import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

/*tslint:disable component-class-suffix component-selector*/
@Component({
  selector: 'field-date',
  template: `
    <input class="form-control"
           autocomplete="off"
           [class.is-invalid]="showError"
           [formlyAttributes]="field"
           [formControl]="formControl"
           [owlDateTimeTrigger]="dt"
           [owlDateTime]="dt"
           [min]="to.minDate"
           [max]="to.maxDate"
    />
    <owl-date-time #dt [pickerType]="to.pickerType" [firstDayOfWeek]="1"></owl-date-time>
  `,
})
export class DateField extends FieldType {
  to: any;
}
