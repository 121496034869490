import {Component, EventEmitter, Input, OnInit, Output, TemplateRef} from '@angular/core';
import {ModalService} from "shared/modules/modal/modal.service";
import {BsModalRef} from "ngx-bootstrap/modal";
import {Observable} from "rxjs";
import {
    PointsService,
    WorkplaceInfoForEmployee
} from "portal/pages/main/place-booking/old-map/map/points/points.service";
import {EmployeeApiModel} from "portal/pages/main/employees/employees.interfaces";
import {catchError, map, switchMap} from "rxjs/operators";
import {BindObservable} from "bind-observable";
import {DownloadService} from "shared/modules/download/download.service";
import {DashboardService} from "portal/pages/dashboard/dashboard.service";
import {HttpClient} from "@angular/common/http";
import {EmployeesFetchService} from "portal/pages/main/employees/employees.service";
import {saveVcard} from "portal/modules/shared/helpers";
import {AsyncPipe, DatePipe, NgClass, NgIf, UpperCasePipe} from "@angular/common";
import {UserPhotoModule} from "portal/modules/user-photo/user-photo.module";
import {PhonePipeModule} from "portal/pipes/phone-pipe.module";
import {RouterLinkWithHref} from "@angular/router";
import {ModalModule} from "shared/modules/modal/modal.module";
import {MyImageCropperModule} from "portal/modules/image-cropper/image-cropper.module";

const clearHeadEmployeeIfHeadEmployeeEqualsEmployeeItself = () => map((employee: EmployeeApiModel) => {
    if (employee.display_name === employee.head_employee_display_name) {
        employee.head_employee_display_name = null;
    }
    return employee;
});

@Component({
    standalone: true,
    selector: 'app-user-profile-info',
    templateUrl: './user-profile-info.component.html',
    imports: [
        NgIf,
        UserPhotoModule,
        NgClass,
        PhonePipeModule,
        RouterLinkWithHref,
        AsyncPipe,
        UpperCasePipe,
        ModalModule,
        MyImageCropperModule,
        DatePipe
    ]
})
export class UserProfileInfoComponent implements OnInit{
    @BindObservable()
    @Input() employee: EmployeeApiModel;
    @Output() employeeChange = new EventEmitter<number>();
    employee$: Observable<EmployeeApiModel>;
    date: Date;
    dateDay: number;
    dateMonth: number;
    workplaceInfoForEmployee$: Observable<WorkplaceInfoForEmployee>;

    modalRef: BsModalRef;

    photo: Blob;

    get birthDate(): Date {
        const birthDate = new Date();
        birthDate.setMonth(this.employee.birth_month - 1);
        birthDate.setDate(this.employee.birth_day);
        return birthDate;
    }

    private employees: EmployeeApiModel[] = [];
    constructor(
        private modalService: ModalService,
        private pointsService: PointsService,
        private downloadService: DownloadService,
        private dashboardService: DashboardService,
        private http: HttpClient,
        private employeesFetchService: EmployeesFetchService,
    ) {
        this.date = new Date();
        this.dateDay = this.date.getDate();
        this.dateMonth = this.date.getMonth() + 1;
    }

    ngOnInit() {
        this.workplaceInfoForEmployee$ = this.employee$.pipe(
            switchMap(({id}) => this.pointsService.getPointInfoForEmployee$(id)),
        );
    }

    async openHead(e: Event) {
        e.preventDefault();
        this.employees = [this.employee, ...this.employees];
        this.employeeChange.emit(this.employee.persistent_head_employee_id);
        this.employee = await this.employeesFetchService
            .get({ id: this.employee.persistent_head_employee_id })
            .pipe(clearHeadEmployeeIfHeadEmployeeEqualsEmployeeItself())
            .toPromise();
    }

    async openPhotoModal(photo_file_guid: string, tpl: TemplateRef<any>[]) {
        if (this.employee?.user_id === this.dashboardService.userInfo.id) {
            this.photo = await this.downloadService
                .getFileContent(photo_file_guid)
                .pipe(catchError(e => this.http.get('./assets/images/no-image.png', {responseType: 'blob'})))
                .toPromise();
            this.modalRef = this.modalService.bsModalService.show(tpl[0]);
        } else {
            this.modalRef = this.modalService.bsModalService.show(tpl[1]);
        }
    }

    savePhoto({file, fileName}) {
        this.employeesFetchService
            .setOwnPhoto(file, fileName)
            .subscribe(({guid, url}) => {
                this.dashboardService.userInfo.employee = {
                    ...this.dashboardService.userInfo.employee,
                    photo_file_guid: guid,
                    photo_url: url,
                };
                this.dashboardService.userInfo = {...this.dashboardService.userInfo};
                this.modalRef.hide();
            });
    }

    saveVcardHandler() {
        saveVcard(this.employee);
    }
}
