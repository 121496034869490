import { EmployeeApiModel } from 'portal/pages/main/employees/employees.interfaces';
import { saveAs } from 'shared/utils/utils';

export function saveVcard(employee: EmployeeApiModel) {
    const vcardParts = `BEGIN:VCARD
VERSION:3.0
N:${employee.surname};${employee.name};;
FN:${employee.display_name}
ORG:${employee.agency_name}
TITLE:${employee.portal_position_name}
PHOTO;GIF:${employee.photo_url}
TEL;CELL;VOICE:+${employee.mobile_phone}
EMAIL;WORK:${employee.email}
END:VCARD`;
    const vcardBlob = new Blob([vcardParts], { type: 'data:text/x-vcard;charset=utf8;' });
    saveAs(vcardBlob, `${employee.display_name}.vcf`);
}
