import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {GiftCategory} from 'portal/pages/administartive/gift-category/gift-category.module';
import {GiftType} from 'portal/pages/administartive/gift-type/gift-type.module';
import {Occasion} from 'portal/pages/administartive/occasions/occasions.module';
import {Module, Permission} from 'portal/pages/administration/roles/roles.interfaces';
import {staffFormPrefix} from 'portal/pages/hr/staff-forms/staff-forms-fetch.service';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, map, shareReplay, switchMap} from 'rxjs/operators';
import {ListResponse} from 'shared/interfaces/list';
import {ApiResponse} from 'shared/interfaces/response';
import {EmployeeApiModel} from "portal/pages/main/employees/employees.interfaces";
import {laravelErrorsToInnerHTML} from "shared/utils/operators";

@Injectable()
export class EnumsService {

    private employeeByLogin$ = new Map<string, Observable<EmployeeApiModel | null>>();

    constructor(private http: HttpClient) {
    }

    getVacationTypes() {
        return this.http.get('/api/v2/vacation/type/list', {}).pipe(
            map((x: any) => x.data.items.map(y => ({value: y.id, label: y.name, threshold_days: y.threshold_days}))),
        );
    }

    getVacationTemplates() {
        return this.http.get('/api/v2/vacation/application/template/list').pipe(
            map((x: any) => x.data.map(y => ({value: y.id, label: y.name}))),
        );
    }

    getHardwareList() {
        return this.http.post('/api/administration/get_hardware_list', {}).pipe(
            map((x: any) => x.data.data.map(y => ({value: y.id, label: `${y.name} / ${y.number}`}))),
        );
    }

    getUsersList() {
        return this.http.get('/api/v2/user/list').pipe(
            map((x: any) => x.data.items.map(y => ({value: y.id, label: y.name}))),
        );
    }

    getEmployeesList() {
        return this.http.get('/api/v2/company/employee/list', {}).pipe(
            map((x: any) => x.data.items.map(y => ({value: y.id, label: y.display_name}))),
        );
    }

    getEmployeesSimpleList() {
        return this.http.get('/api/v2/company/employee/simple/list', {}).pipe(
            map((x: any) => x.data.items.map(y => ({value: y.id, label: y.display_name}))),
        );
    }

    getEmploymentStatusList() {
        return this.http.get('/api/v2/company/employee/employment-status/list').pipe(
            map((x: any) => x.data.map(y => ({value: y.id, label: y.name}))),
        );
    }

    getUnitsList() {
        return this.http.get('/api/v2/time-tracking/department/list').pipe(
            map((x: any) => x.data.items.map(y => ({value: y.id, label: y.name}))),
        );
    }

    getClientsList() {
        return this.http.get('/api/v2/client/list').pipe(
            map((x: any) => x.data.items.map(y => ({value: y.id, label: y.name}))),
        );
    }

    getAgencyList(): Observable<{ label: string, value: any }[]> {
        return this.http.get('/api/v2/company/agency/list').pipe(
            map((x: any) => x.data.items.map(y => ({value: y.id, label: y.name}))),
        );
    }

    getCompanyList() {
        return this.http.get('/api/v2/company/company/list').pipe(
            map((x: any) => x.data.items.map(y => ({value: y.id, label: y.name}))),
        );
    }

    getDepartmentList() {
        return this.http.get('/api/v2/company/portal-department/list').pipe(
            map((x: any) => x.data.items.map(y => ({value: y.id, label: y.name}))),
        );
    }

    getPortalDivisionsList() {
        return this.http.get('/api/v2/company/portal-division/list').pipe(
            map((x: any) => x.data.items.map(y => ({value: y.id, label: y.name}))),
        );
    }

    getOfficialDepartementList() {
        return this.http.get('/api/v2/company/official-department/list').pipe(
            map((x: any) => x.data.items.map(y => ({value: y.id, label: y.name}))),
        );
    }

    getPositionList() {
        return this.http.get('/api/v2/company/portal-position/list').pipe(
            map((x: any) => x.data.items.map(y => ({value: y.id, label: y.name, en_label: y.eng_name}))),
        );
    }

    getEnPositionList() {
        return this.http.get('/api/v2/company/portal-position/list').pipe(
            map((x: any) => x.data.items.map(y => ({value: y.id, label: y.eng_name}))),
        );
    }

    getOfficialPositionList() {
        return this.http.get('/api/v2/company/official-position/list').pipe(
            map((x: any) => x.data.items.map(y => ({value: y.id, label: y.name}))),
        );
    }


    getActivityTypeList() {
        return this.http.get<ApiResponse<{ id, name }[]>>('api/v2/time-tracking/ref/activity-type/list').pipe(
            map(x => x.data.map(y => ({value: y.id, label: y.name}))),
        );
    }

    getEmployeeHeadList() {
        return this.http.get('/api/v2/company/employee/simple/list').pipe(
            map((x: any) => x.data.items.map(y => ({value: y.id, label: y.display_name}))),
        );
    }

    getCurrentEmployeeHierarchyList() {
        return this.http.get('/api/v2/company/employee/hierarchy/list').pipe(
            map((x: any) => x.data.map(y => ({value: y.id, label: y.display_name}))),
        );
    }

    getEmploymentTypeList() {
        return this.http.get('/api/v2/company/employee/employment-type/list').pipe(
            map((x: any) => x.data.map(y => ({value: y.id, label: y.name}))),
        );
    }

    getEmployeeHrList() {
        return this.http.get('/api/v2/company/employee/simple/list/hr').pipe(
            map((x: any) => x.data.items.map(y => ({value: y.id, label: y.display_name}))),
        );
    }

    getCeoBoardClientsList() {
        return this.http.get('/api/v2/ceo-board/ref/client/list').pipe(
            map((x: any) => x.data.map(y => ({value: y.guid, label: y.name}))),
        );
    }

    getModules() {
        return this.http.get<ApiResponse<Module[]>>('api/v2/role/module/list')
            .pipe(map(it => it.data.map(y => ({value: y.id, label: y.name}))));
    }

    getPermissionList(forModuleWithId: number) {
        let apiUrl = '/api/v2/role/permission/list';
        if (forModuleWithId) {
            apiUrl += `?module_id=${forModuleWithId}`;
        }
        return this.http.get<ApiResponse<Permission[]>>(apiUrl).pipe(
            map((x) => x.data.map(y => ({value: y.alias, label: y.name}))),
        );
    }

    getV2RolesList(forModuleWithId: number) {
        let apiUrl = 'api/v2/role/role/list';
        if (forModuleWithId) {
            apiUrl += `?module_id=${forModuleWithId}`;
        }
        return this.http.get(apiUrl).pipe(
            map((x: any) => x.data.items.map(y => ({value: y.id, label: y.name}))),
        );
    }

    getStaffFormTypesList() {
        return this.http.get<ApiResponse<any>>(`${staffFormPrefix}/type/list`)
            .pipe(map(it => it.data.map(y => ({value: y.id, label: y.name, is_new_employee: y.is_new_employee}))));
    }

    getServiceDeskCategories() {
        return this.http.get<ApiResponse<any>>(`api/v2/service-desk/ref/categories`)
            .pipe(map(it => it.data.map(y => ({value: y.value, label: y.label}))));
    }

    getServiceDeskRequestTypes(categoryId: number = null) {
        return this.http.get<ApiResponse<any>>(`api/v2/service-desk/ref/types?category_id=${categoryId}`)
            .pipe(map(it => it.data.map(y => ({value: y.value, label: y.label}))));
    }

    getServiceDeskRequestStatuses() {
        return this.http.get<ApiResponse<any>>(`api/v2/service-desk/ref/statuses`)
            .pipe(map(it => it.data.map(y => ({value: y.value, label: y.label}))));
    }

    getMapPlaces() {
        return this.http.get<ApiResponse<any>>(`api/v2/office-map/object/simple-list`)
            .pipe(map(it => it.data.map(y => ({value: y.value, label: y.label}))));
    }

    getSections() {
        return this.http.get<ApiResponse<any>>(`api/v2/section/list`)
            .pipe(map(it => it.data.items));
    }

    /* GIFTS */

    getGiftCategories() {
        return this.http.get<ListResponse<GiftCategory>>(`api/v2/gift/category/list`)
            .pipe(map(it => it.data.items.map(y => ({value: y.id, label: y.name}))));
    }

    getGiftTypes() {
        return this.http.get<ListResponse<GiftType>>(`api/v2/gift/type/list`)
            .pipe(map(it => it.data.items.map(y => ({value: y.id, label: y.name}))));
    }

    getGiftOccasions() {
        return this.http.get<ListResponse<Occasion>>(`api/v2/gift/occasion/list`)
            .pipe(map(it => it.data.items.map(y => ({value: y.id, label: y.name}))));
    }

    /* END GIFTS */

    /* DATAFEED*/

    getDatafeedGeocodeList() {
        return this.http.get<ApiResponse<{ id: number, name: string, code: string }[]>>(`api/v2/datafeed/geocode/list`)
            .pipe(map(it => it.data.map(y => ({value: y.id, label: y.name}))));
    }

    getDatafeedSourceSimpleList() {
        return this.http.get<ApiResponse<{ id: number, alias: string }[]>>(`api/v2/datafeed/source/simple-list`)
            .pipe(map(it => it.data.map(y => ({value: y.id, label: y.alias}))));
    }

    getDatafeedSourceGroupSimpleList() {
        return this.http.get<ApiResponse<string[]>>(`api/v2/datafeed/source/group-list`)
            .pipe(map(it => it.data.map(y => ({value: y, label: y}))));
    }

    getDatafeedCategoryList() {
        return this.http.get<ApiResponse<{ id: number, name: string }[]>>(`api/v2/datafeed/category/list`)
            .pipe(map(it => it.data.map(y => ({value: y.id, label: y.name}))));
    }

    getDatafeedVendorlist() {
        return this.http.get<ApiResponse<{ id: number, name: string }[]>>(`api/v2/datafeed/vendor/list`)
            .pipe(map(it => it.data.map(y => ({value: y.id, label: y.name}))));
    }

    getDatafeedModellist() {
        return this.http.get<ApiResponse<{
            id: number,
            name: string,
            vendor_id: number
        }[]>>(`api/v2/datafeed/model/list`)
            .pipe(map(it => it.data.map(y => ({value: y.id, label: y.name, vendor_id: y.vendor_id}))));
    }

    getAllBrands() {
        return this.http.get<ApiResponse<{ id: number, name: string }[]>>(`api/v2/client/brand/list`)
            .pipe(
                map(it => it.data.map(y => ({value: y.id, label: y.name}))),
                shareReplay(),
            );
    }

    getClientBrandList(clientId: number) {
        return this.http.get<ApiResponse<{ id: number, name: string }[]>>(`api/v2/client/${clientId}/brand/list`)
            .pipe(map(it => it.data.map(y => ({value: y.id, label: y.name}))));
    }

    employeeByLogin(login: string) {
        if (!this.employeeByLogin$.has(login)) {
            this.employeeByLogin$.set(login,
                this.http.get<ApiResponse<EmployeeApiModel>>(`/api/v2/company/employee/by-login/${login}`)
                    .pipe(
                        laravelErrorsToInnerHTML(),
                        map(it => it.data),
                        shareReplay(1),
                        catchError(() => of(null))
                    )
            );
        }
        return this.employeeByLogin$.get(login)!;
    }
}
