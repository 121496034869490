<app-modal *ngIf="employee" [modalRef]="ref" >
    <i (click)="goBack()" [class.d-none]="!canGoBack"
       class="fa fa-arrow-left icon-button header me-3 align-self-center"></i>
    <i class="d-block d-md-none fa fa-download icon-button header me-3 align-self-center" (click)="saveVcard()"></i>
    <!-- <div class="row"> -->
    <!--     <div class="col-12 col-lg-6 d-flex flex-column align-items-center justify-content-between"> -->
    <!--         <app-user-photo -->
    <!--             class="shadow" -->
    <!--             [photoUrl]="employee.photo_url" -->
    <!--             [style.width]="'200px'" -->
    <!--             [style.height]="'200px'" -->
    <!--             > -->
    <!--         </app-user-photo> -->
    <!--         <div class="d-flex flex-column mt-3 names"> -->
    <!--             <h5 class="d-flex align-items-center justify-content-center"> -->
    <!--                     <span *ngIf="employee.employment_status_name === 'В декрете'" -->
    <!--                           class="badge bg-secondary me-1"> -->
    <!--                         В декрете -->
    <!--                     </span> -->
    <!--                 <button *ngIf="employee.email else employeeNameWithoutEmail" -->
    <!--                    (click)="goToUserProfile(employee.email)" -->
    <!--                    class="btn btn-link" -->
    <!--                 > -->
    <!--                     <u>{{ employee.surname }} {{ employee.name }} {{ employee.patronymic }}</u> -->
    <!--                 </button> -->
    <!--                 <ng-template #employeeNameWithoutEmail> -->
    <!--                     <span -->
    <!--                         *ngIf="!employee.email" -->
    <!--                     > -->
    <!--                         {{ employee.surname }} {{ employee.name }} {{ employee.patronymic }} -->
    <!--                     </span> -->
    <!--                 </ng-template> -->
    <!--             </h5> -->
    <!--             <p *ngIf="employee.portal_position_name" -->
    <!--                class="text-muted mb-0 position text-center">{{ employee.portal_position_name }}</p> -->
    <!--         </div> -->
    <!--     </div> -->
    <!--     <div class="col-12 col-lg-6 d-flex flex-column justify-content-between info"> -->
    <!--         <dl *ngIf="employee.mobile_phone"> -->
    <!--             <dt class="text-muted">Телефон:</dt> -->
    <!--             <dd> -->
    <!--                 <i class="fa fa-fw fa-mobile"></i> -->
    <!--                 <a [href]="'tel:' + employee.mobile_phone"><span>{{ employee.mobile_phone | phone }}</span></a> -->
    <!--             </dd> -->
    <!--         </dl> -->
    <!--         <dl *ngIf="employee.internal_phone"> -->
    <!--             <dt class="text-muted">Внутренний телефон:</dt> -->
    <!--             <dd> -->
    <!--                 <i class="fa fa-fw fa-phone"></i> -->
    <!--                 <span>{{ employee.cross_agency_phone }}</span> -->
    <!--             </dd> -->
    <!--         </dl> -->
    <!--         <dl *ngIf="employee.email"> -->
    <!--             <dt class="text-muted">Эл. почта:</dt> -->
    <!--             <dd> -->
    <!--                 <i class="fa fa-fw fa-envelope"></i> -->
    <!--                 <a href="mailto:{{ employee.email  }}">{{ employee.email }}</a> -->
    <!--             </dd> -->
    <!--         </dl> -->
    <!--         <dl *ngIf="employee.agency_name"> -->
    <!--             <dt class="text-muted">Агентство:</dt> -->
    <!--             <dd>{{ employee.agency_name }}</dd> -->
    <!--         </dl> -->
    <!--         <dl *ngIf="employee.portal_department_name"> -->
    <!--             <dt class="text-muted">Департамент:</dt> -->
    <!--             <dd>{{ employee.portal_department_name }}</dd> -->
    <!--         </dl> -->
    <!--         <ng-container *ngIf="workplaceInfoForEmployee$ | async as workplace"> -->
    <!--             <dl> -->
    <!--                 <dt class="text-muted">Этаж:</dt> -->
    <!--                 <dd> -->
    <!--                     {{ workplace.floor | uppercase }} -->
    <!--                     <a [routerLink]="['./map', workplace.floor]" -->
    <!--                        [queryParams]="{ search: employee.display_name }" -->
    <!--                        (click)="ref.hide()" -->
    <!--                     >(показать на карте)</a> -->
    <!--                 </dd> -->
    <!--             </dl> -->
    <!--         </ng-container> -->
    <!--         <dl *ngIf="employee.head_employee_display_name"> -->
    <!--             <dt class="text-muted">Руководитель:</dt> -->
    <!--             <dd><a (click)="openHead($event)" href="#">{{ employee.head_employee_display_name }}</a></dd> -->
    <!--         </dl> -->
    <!--         <dl *ngIf="employee.email"> -->
    <!--             <dt class="text-muted">&nbsp;</dt> -->
    <!--             <dd><i class="far fa-fw fa-id-badge"></i> -->
    <!--                 <button *ngIf="employee.email" class="btn btn-link" (click)="goToUserProfile(employee.email)">Профиль</button> -->
    <!--             </dd> -->
    <!--         </dl> -->
    <!--     </div> -->
    <!-- </div> -->
    <!-- <div class="row"> -->
    <!--     <dl *ngIf="employee.about" class="mx-3"> -->
    <!--         <dt class="text-muted">О себе:</dt> -->
    <!--         <dd>{{ employee.about }}</dd> -->
    <!--     </dl> -->
    <!--     <dl *ngIf="employee.dont_notify_about_birthday === false && birthDate" class="mx-3"> -->
    <!--         <dt class="text-muted">День рождения:</dt> -->
    <!--         <dd>{{ birthDate | date : 'dd MMMM' }}</dd> -->
    <!--     </dl> -->
    <!-- </div> -->
    <div class="p-3">
        <app-user-profile-info [employee]="employee"></app-user-profile-info>
    </div>
</app-modal>
